import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _61f02036 = () => interopDefault(import('../pages/check-email.vue' /* webpackChunkName: "pages/check-email" */))
const _0a81d775 = () => interopDefault(import('../pages/ian.vue' /* webpackChunkName: "pages/ian" */))
const _634dad70 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _49dd083b = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _1323d2e6 = () => interopDefault(import('../pages/medications/index.vue' /* webpackChunkName: "pages/medications/index" */))
const _4c1c790c = () => interopDefault(import('../pages/password-updated.vue' /* webpackChunkName: "pages/password-updated" */))
const _51ae7a05 = () => interopDefault(import('../pages/paylinks/index.vue' /* webpackChunkName: "pages/paylinks/index" */))
const _64bc96fb = () => interopDefault(import('../pages/prescriptions/index.vue' /* webpackChunkName: "pages/prescriptions/index" */))
const _8fc7ead8 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _7e00a0b3 = () => interopDefault(import('../pages/registered.vue' /* webpackChunkName: "pages/registered" */))
const _7dfbef26 = () => interopDefault(import('../pages/resend-confirmation.vue' /* webpackChunkName: "pages/resend-confirmation" */))
const _3a7018ec = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _3dbfd814 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _64ce7a1d = () => interopDefault(import('../pages/settings/close-account.vue' /* webpackChunkName: "pages/settings/close-account" */))
const _012894bf = () => interopDefault(import('../pages/settings/export.vue' /* webpackChunkName: "pages/settings/export" */))
const _046ff641 = () => interopDefault(import('../pages/settings/hospitals/index.vue' /* webpackChunkName: "pages/settings/hospitals/index" */))
const _e1d7ffba = () => interopDefault(import('../pages/settings/options.vue' /* webpackChunkName: "pages/settings/options" */))
const _65d70d9c = () => interopDefault(import('../pages/settings/pharmacies.vue' /* webpackChunkName: "pages/settings/pharmacies" */))
const _08e5fcea = () => interopDefault(import('../pages/settings/security.vue' /* webpackChunkName: "pages/settings/security" */))
const _037c0a16 = () => interopDefault(import('../pages/settings/user.vue' /* webpackChunkName: "pages/settings/user" */))
const _c7e32c62 = () => interopDefault(import('../pages/settings/hospitals/new.vue' /* webpackChunkName: "pages/settings/hospitals/new" */))
const _39f208ac = () => interopDefault(import('../pages/settings/hospitals/_id/index.vue' /* webpackChunkName: "pages/settings/hospitals/_id/index" */))
const _41d05520 = () => interopDefault(import('../pages/settings/hospitals/_id/edit.vue' /* webpackChunkName: "pages/settings/hospitals/_id/edit" */))
const _037afaa4 = () => interopDefault(import('../pages/wrapped.vue' /* webpackChunkName: "pages/wrapped" */))
const _ba0186a6 = () => interopDefault(import('../pages/embed/rx.vue' /* webpackChunkName: "pages/embed/rx" */))
const _5a9ff109 = () => interopDefault(import('../pages/prescriptions/new.vue' /* webpackChunkName: "pages/prescriptions/new" */))
const _5ee7435e = () => interopDefault(import('../pages/redirect/new.vue' /* webpackChunkName: "pages/redirect/new" */))
const _04b18631 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4ff7d8bd = () => interopDefault(import('../pages/paylinks/_slug.vue' /* webpackChunkName: "pages/paylinks/_slug" */))
const _44febf60 = () => interopDefault(import('../pages/prescriptions/_signature.vue' /* webpackChunkName: "pages/prescriptions/_signature" */))
const _3c9ea0c2 = () => interopDefault(import('../pages/new-password/_uid/_token.vue' /* webpackChunkName: "pages/new-password/_uid/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/check-email",
    component: _61f02036,
    name: "check-email"
  }, {
    path: "/ian",
    component: _0a81d775,
    name: "ian"
  }, {
    path: "/login",
    component: _634dad70,
    name: "login"
  }, {
    path: "/logout",
    component: _49dd083b,
    name: "logout"
  }, {
    path: "/medications",
    component: _1323d2e6,
    name: "medications"
  }, {
    path: "/password-updated",
    component: _4c1c790c,
    name: "password-updated"
  }, {
    path: "/paylinks",
    component: _51ae7a05,
    name: "paylinks"
  }, {
    path: "/prescriptions",
    component: _64bc96fb,
    name: "prescriptions"
  }, {
    path: "/register",
    component: _8fc7ead8,
    name: "register"
  }, {
    path: "/registered",
    component: _7e00a0b3,
    name: "registered"
  }, {
    path: "/resend-confirmation",
    component: _7dfbef26,
    name: "resend-confirmation"
  }, {
    path: "/reset-password",
    component: _3a7018ec,
    name: "reset-password"
  }, {
    path: "/settings",
    component: _3dbfd814,
    name: "settings",
    children: [{
      path: "close-account",
      component: _64ce7a1d,
      name: "settings-close-account"
    }, {
      path: "export",
      component: _012894bf,
      name: "settings-export"
    }, {
      path: "hospitals",
      component: _046ff641,
      name: "settings-hospitals"
    }, {
      path: "options",
      component: _e1d7ffba,
      name: "settings-options"
    }, {
      path: "pharmacies",
      component: _65d70d9c,
      name: "settings-pharmacies"
    }, {
      path: "security",
      component: _08e5fcea,
      name: "settings-security"
    }, {
      path: "user",
      component: _037c0a16,
      name: "settings-user"
    }, {
      path: "hospitals/new",
      component: _c7e32c62,
      name: "settings-hospitals-new"
    }, {
      path: "hospitals/:id",
      component: _39f208ac,
      name: "settings-hospitals-id"
    }, {
      path: "hospitals/:id/edit",
      component: _41d05520,
      name: "settings-hospitals-id-edit"
    }]
  }, {
    path: "/wrapped",
    component: _037afaa4,
    name: "wrapped"
  }, {
    path: "/embed/rx",
    component: _ba0186a6,
    name: "embed-rx"
  }, {
    path: "/prescriptions/new",
    component: _5a9ff109,
    name: "prescriptions-new"
  }, {
    path: "/redirect/new",
    component: _5ee7435e,
    name: "redirect-new"
  }, {
    path: "/",
    component: _04b18631,
    name: "index"
  }, {
    path: "/paylinks/:slug",
    component: _4ff7d8bd,
    name: "paylinks-slug"
  }, {
    path: "/prescriptions/:signature",
    component: _44febf60,
    name: "prescriptions-signature"
  }, {
    path: "/new-password/:uid?/:token?",
    component: _3c9ea0c2,
    name: "new-password-uid-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
